@import "../../../assets/styles/common";

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #fff;
  z-index: 1060;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .spinner {
    width: 150px;
    height: 150px;
    //background: url(../../../assets/spinner.svg) no-repeat;
    animation: spinner 1.5s linear infinite;
    .st0{fill: var(--color-dark-default);}
    .st1{fill: var(--color-primary);}
    .st2{fill: var(--color-primary);}
    .st3{fill: var(--color-primary);}
  }
}
