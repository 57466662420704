@mixin boxShadowOnHover() {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
}
@mixin boxShadow() {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
}

// Colors
$light-red: #D32F2F;

$content-width: 1180px;

$side-nav-margin: 18px;

.link {
  color: var(--color-primary);
  &:hover {
    color: var(--color-primary-hover);
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    color: var(--color-primary);
  }
  &:active {
    color: var(--color-text);
  }
}

.hidden {
  display: none !important;
}
