@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather'), url(../fonts/Merriweather/Merriweather-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  src: local('NotoSans'), url(../fonts/NotoSans/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(../fonts/Nunito/Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(../fonts/OpenSans/OpenSansRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: local('OpenSansCondensed'), url(../fonts/OpenSansCondensed/OpenSansCondensed-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(../fonts/Oswald/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Playfair Display';
  src: local('PlayfairDisplay'), url(../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PT Sans';
  src: local('PTSans'), url(../fonts/PTSans/PTSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../fonts/Raleway/Raleway-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('RobotoCondensed'), url(../fonts/RobotoCondensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('RobotoMono'), url(../fonts/RobotoMono/RobotoMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Slab';
  src: local('RobotoSlab'), url(../fonts/RobotoSlab/RobotoSlab-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(../fonts/Rubik/Rubik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro'), url(../fonts/SourceSansPro/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), url(../fonts/Ubuntu/Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Sackers Gothic';
  src: local('SackersGothicStd-Heavy'), url(../fonts/SackersGothic/SackersGothicStd-Heavy.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Sackers Gothic';
  src: local('SackersGothicStd-Medium'), url(../fonts/SackersGothic/SackersGothicStd-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Sackers Gothic';
  src: local('SackersGothicStd-Light'), url(../fonts/SackersGothic/SackersGothicStd-Light.otf) format('opentype');
  font-weight: 300;
}