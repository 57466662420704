@import "./fonts";
@import "~rc-slider/assets/index.css";


html {
  width: 100%;
  //height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
#root, #root > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
