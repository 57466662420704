@import "../../../assets/styles/common";

.notification-background {
  position: absolute;
  top: 6px;
  right: 16px;
  width: 70px;
  height: 70px;
  z-index: 9990;
  &.notification-success {
    width: 90px;
    background: url(../../../assets/icons/notification_check.svg) no-repeat
      transparent;
  }
  &.notification-error {
    background: url(../../../assets/icons/notification_error.svg) no-repeat
      transparent;
  }
}

.notification-action-button {
  margin: 10px 0px 0px;
}
